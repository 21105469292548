import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';

import { Layout } from '../components/index';
import { classNames, htmlToReact } from '../utils';
import ThemeContext from '../context/ThemeContext';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Page extends React.Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Layout {...this.props}>
            <article className={classNames('page', { dark: theme.dark })}>
              <div className="container container--md">
                <header className={classNames('page__header', { dark: theme.dark })}>
                  <h1 className="page__title">
                    {_.get(this.props, 'pageContext.frontmatter.title', null)}
                  </h1>
                </header>
                <div className={classNames('page__copy', { dark: theme.dark })}>
                  {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                </div>
              </div>
            </article>
          </Layout>
        )}
      </ThemeContext.Consumer>
    );
  }
}
